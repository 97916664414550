import React from "react";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAsia, faLink } from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import "./SearchCard.scss";

export default function SearchCard(props) {
  const masjid = {
    name: props.name,
    streetAddress: props.streetAddress,
    city: props.city,
    state: props.state,
    postalCode: props.postalCode,
    country: props.country,
    liveStream: props.liveStream,
    website: props.website,
    profile: props.profile,
    distance: parseInt(props.distance, 10)
      ? parseInt(props.distance, 10) + " miles away"
      : "",
  };

  let loggedIn = (masjid.liveStream === 'http://cybermasjid.com/') ? false : true;
  let hideCopyLink = (loggedIn) ? '' : 'd-none'; 

  const copyToClipboard = str => {
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';                 
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =            
    document.getSelection().rangeCount > 0        // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0)     // Store selection if found
      : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element

    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }
  };

  return (
    <Card
      className="card-box-shadow back-design"
      style={{ boxShadow: "5px", margin: "20px", height: "250px" }}
    >
      <Card.Body style={{ fontSize: "15px", textAlign: "left" }}>
        <Card.Title>{masjid.name}</Card.Title>
        <Card.Text style={{ height: "100px", marginBottom: "5px" }}>
          {masjid.streetAddress}
          <br />
          {masjid.city}, {masjid.state} {masjid.postalCode}
          <br />
          {masjid.country}
          <br />
          <span className="text-muted" style={{ lineHeight: "30px" }}>
            {" "}
            {masjid.distance}{" "}
          </span>
        </Card.Text>
        <Card.Text style={{ height: "40px" }}>
          <FontAwesomeIcon
            className="text-muted"
            icon={faGlobeAsia}
            style={{ marginRight: "6px" }}
          />{" "}
          <Card.Link href={masjid.website}>Website</Card.Link>
          <br />
          <FontAwesomeIcon
            className="text-muted"
            icon={faYoutube}
            style={{ marginRight: "6px" }}
          />{" "}
          <Card.Link href={masjid.liveStream}>Livestream</Card.Link>
          &nbsp; &nbsp;
          <FontAwesomeIcon
            className={'text-muted ' + hideCopyLink}
            icon={faLink}
            style={{ marginRight: "6px" }}
          />
          <Card.Link className={hideCopyLink} href={masjid.liveStream} onClick={e => { e.preventDefault(); copyToClipboard(masjid.liveStream); }}>Copy</Card.Link>
          <br />
        </Card.Text>
      </Card.Body>
      <Card.Footer style={{ padding: 5 }}></Card.Footer>
    </Card>
  );
}
