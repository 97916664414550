import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./App.scss";
import Header from "./Header";
import Submission from "./Submission";
import ListSubmissions from "./ListSubmissions";
import Home from "./Home";
import ContactUs from "./ContactUs";
import Fatwas from "./Fatwas";
import FAQ from "./FAQ";
import WhyCyber from "./WhyCyber";
import PrivacyPolicy from "./PrivacyPolicy";

function App() {
  return (
    <div>
      <Header></Header>

      <Container>
        <Router>
          <Switch>
            <Route path="/contact-us">
              <ContactUs />
            </Route>
            <Route path="/why-cyber">
              <WhyCyber />
            </Route>
            <Route path="/fatwas">
              <Fatwas />
            </Route>
            <Route path="/faq">
              <FAQ />
            </Route>
            <Route path="/contact-us">
              <ContactUs />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/submission">
              <Submission />
            </Route>
            <Route path="/list-submissions">
              <ListSubmissions />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </Container>
      <footer className="footer">
        <Container
          className="text-muted"
          style={{ padding: "20px", paddingTop: "40px", width: "80%" }}
        >
          <Row>
            <Col sm={{ span: 3 }}>
              &copy; cyber masjid {new Date().getFullYear()}
            </Col>
            <Col sm={{ offset: 1, span: 2 }}>
              <ul className="list-unstyled text-small">
                <li>
                  <a className="text-muted" href="/contact-us">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a className="text-muted" href="/why-cyber">
                    Why Cyber Masjid
                  </a>
                </li>
              </ul>
            </Col>
            <Col sm={{ offset: 1, span: 2 }}>
              <ul className="list-unstyled text-small">
                <li>
                  <a className="text-muted" href="/submission">
                    Submit a Masjid
                  </a>
                </li>
              </ul>
            </Col>
            <Col sm={{ offset: 1, span: 2 }}>
              <ul className="list-unstyled text-small">
                <li>
                  <a className="text-muted" href="/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default App;
