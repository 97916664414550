import React, { useState } from "react";
import Toast from "react-bootstrap/Toast";

export default function Example(props) {
  const toast = props.toaster;
  const setCookie = props.setCookie;

  const title = toast.title || "Cyber Masjid";
  const duration = toast.duration || "";
  const message = toast.message;

  const initialState = message ? true : false;
  const [show, setShow] = useState(initialState);

  const removeToast = () => {
    setShow(false);
    setCookie("toaster", "", { path: "/" });
  };

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: "relative",
      }}
    >
      <div style={{ position: "absolute", top: 10, right: 10 }}>
        <Toast
          style={{ margin: 10 }}
          onClose={removeToast}
          show={show}
          delay={4000}
          autohide
        >
          <Toast.Header
            style={{ backgroundColor: "#5CB85C", color: "#FFFFFF" }}
          >
            <strong className="mr-auto">{title}</strong>
            <small>{duration}</small>
          </Toast.Header>
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
}
