import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useCookies } from "react-cookie";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import authAPI from "./auth-api";
import AuthModal from "./AuthModal";
import Toast from "./Toast";
import logo from "../images/logo.png";

let profile;
export default function Header() {
  const clientId =
    "908935358050-f84flno3n9nclic1hk54vefqh3jdivs8.apps.googleusercontent.com";
  const [cookies, setCookie, removeCookie] = useCookies(["session", "toaster"]);

  let toast = "";
  if (cookies.toaster) {
    toast = <Toast toaster={cookies.toaster} setCookie={setCookie} />;
  }

  const authSuccess = async (googleUser) => {
    try {
      const googleIdToken = googleUser.getAuthResponse().id_token;
      profile = googleUser.getBasicProfile();

      const [body] = await authAPI.init(googleIdToken, { responseJson: true });

      const session = { profile, googleIdToken };
      const sp = body["x-sp-set"];
      setCookie("session", session, { path: "/" });
      if (sp) setCookie("x-sp-set", sp, { path: "/" });

      setSignInButton(
        <GoogleLogout
          clientId={clientId}
          buttonText="Logout"
          onLogoutSuccess={logout}
        ></GoogleLogout>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const authFail = (response) => {
    console.log(response);
  };

  const [signInButton, setSignInButton] = useState(
    <GoogleLogin
      clientId={clientId}
      buttonText="Sign In"
      onSuccess={authSuccess}
      onFailure={authFail}
      isSignedIn={true}
      cookiePolicy={"single_host_origin"}
    />
  );

  const logout = (response) => {
    profile = null;
    removeCookie("session");

    setSignInButton(
      <GoogleLogin
        clientId={clientId}
        buttonText="Login"
        onSuccess={authSuccess}
        onFailure={authFail}
        isSignedIn={true}
        cookiePolicy={"single_host_origin"}
      />
    );
    console.log("logged out");
    document.location = "/";
  };

  const [showAuthModal, setShowAuthModal] = useState(false);

  const masjidSubmission = (e) => {
    if (profile) {
      console.log("redirect");
      document.location = "/submission";
    } else {
      console.log("modal");
      setShowAuthModal(true);
    }
  };

  const linkStyle = { marginLeft: "10px", marginRight: "10px" };

  return (
    <div style={{ marginBottom: "30px" }}>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Navbar.Brand
          href="/"
          style={{ marginTop: "5px", marginLeft: "20px", marginRight: "20px" }}
        >
          <img
            src={logo}
            alt="Cybermasjid"
            style={{ width: "30px", marginBottom: "5px", marginRight: "5px" }}
          />
          cyber masjid
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/why-cyber" style={linkStyle}>
              Why Cyber Masjid
            </Nav.Link>
            <Nav.Link href="/fatwas" style={linkStyle}>
              Fatwas
            </Nav.Link>
            <Nav.Link href="/faq" style={linkStyle}>
              FAQ
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link>
              <Button onClick={masjidSubmission} style={{ marginTop: "4px" }}>
                Masjid Submission
              </Button>
              <AuthModal
                show={showAuthModal}
                onHide={() => setShowAuthModal(false)}
              />
            </Nav.Link>
            <Nav.Link>{signInButton}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {toast}
    </div>
  );
}
