import StandardAPI from "./standard-api";

const searchMasjidAPI = new StandardAPI("/v1/masjids");

export const searchMasjid = async (token, query) => {
  if (token) searchMasjidAPI.headers["Authorization"] = `Bearer ${token}`;

  try {
    return searchMasjidAPI.get(null, query);
  } catch (error) {
    console.log(error);
  }
};

export default {
  searchMasjid,
};
