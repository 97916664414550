import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SearchCard from "./SearchCard";
import { searchMasjid } from "./SearchMasjidAPI";

export default function SearchResults(props) {
  // const searchCity = props.searchCity;
  const [masjids, setMasjid] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["session", "toaster"]);

  console.log({ props });

  const lat = props.coordinates.lat; // 37.7749;
  const lng = props.coordinates.lng; //-122.419;

  useEffect(() => {
    (async () => {
      const session = cookies.session;
      const token = session && session.googleIdToken ? session.googleIdToken : null;
      const searchResult = await searchMasjid(token, { lat, lng });
      if (searchResult.masjids) {
        setMasjid(searchResult.masjids);
      }
    })();
  }, [lat, lng]);

  let cards = [];
  let rows = [];
  if (masjids.length) {
    cards = masjids.map((masjid) => (
      <SearchCard
        key={masjid.externalId}
        name={masjid.name}
        profile={masjid.userProfile}
        streetAddress={masjid.streetAddress}
        city={masjid.city}
        state={masjid.state}
        postalCode={masjid.postalCode}
        country={masjid.country}
        distance={masjid.distanceInMiles}
        liveStream={masjid.liveStream}
        website={masjid.website}
      />
    ));

    for (let n = 0; n < cards.length; n = n + 2) {
      rows.push(
        <Row style={{ width: "100%" }}>
          <Col sm="6">{cards[n]}</Col>
          <Col sm="6">{cards[n + 1] ? cards[n + 1] : ""}</Col>
        </Row>
      );
    }
  }

  return (
    <div style={{ width: "100%", paddingTop: "20px" }}>
      Showing results from near: {props.searchCity}
      {rows}
    </div>
  );
}
