import React, { useState } from "react";
import { useCookies } from "react-cookie";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { rejectSubmission, updateSubmission } from "./SubmissionAPI";

export default function SubmissionForm(props) {
  const masjid = props.masjid;
  const [cookies] = useCookies(["session"]);
  const [showForm, setShowForm] = useState(true);

  const [name, setName] = useState(masjid.name);
  const [position, setPosition] = useState(masjid.position);
  const [streetAddress, setStreetAddress] = useState(masjid.streetAddress);
  const [city, setCity] = useState(masjid.city);
  const [state, setState] = useState(masjid.state);
  const [country, setCountry] = useState(masjid.country);
  const [postalCode, setPostalCode] = useState(masjid.postalCode);
  const [lat, setLat] = useState(masjid.lat);
  const [lng, setLng] = useState(masjid.lng);
  const [website, setWebsite] = useState(masjid.website);
  const [liveStream, setLiveStream] = useState(masjid.liveStream);

  const rejectCurrentSubmission = async () => {
    const session = cookies.session || null;
    if (!session.googleIdToken) document.location("/");

    const token = session.googleIdToken;

    try {
      await rejectSubmission(token, masjid.externalId);
      setShowForm(false);
    } catch (error) {
      console.error(error);
    }
  };

  const markVerified = async () => {
    const session = cookies.session || null;
    if (!session.googleIdToken) document.location("/");

    const token = session.googleIdToken;

    const masjidData = {
      id: masjid.id,
      externalId: masjid.externalId,
      name,
      position,
      streetAddress,
      postalCode,
      city,
      state,
      country,
      lat,
      lng,
      website,
      liveStream,
    };

    try {
      await updateSubmission(token, masjid.externalId, masjidData);
      setShowForm(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form
      className={showForm ? "" : "d-none"}
      style={{
        width: "100%",
        fontSize: "14px",
        marginTop: "20px",
        textAlign: "left",
        backgroundColor: "#EEE",
        padding: "30px",
      }}
    >
      <Form.Row>
        <Form.Group as={Col} sm="2">
          <Form.Label>Database Id</Form.Label>
          <Form.Control size="sm" readOnly value={masjid.id} />
        </Form.Group>

        <Form.Group as={Col} sm="2">
          <Form.Label>External Id</Form.Label>
          <Form.Control size="sm" readOnly value={masjid.externalId} />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} sm="6">
          <Form.Label>Masjid Name</Form.Label>
          <Form.Control
            size="sm"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} sm="4">
          <Form.Label>Reporter Name</Form.Label>
          <Form.Control size="sm" readOnly value={masjid.userProfile.name} />
        </Form.Group>

        <Form.Group as={Col} sm="5">
          <Form.Label>Reporter Email</Form.Label>
          <Form.Control size="sm" readOnly value={masjid.userProfile.email} />
        </Form.Group>

        <Form.Group as={Col} sm="3">
          <Form.Label>Reporter Position</Form.Label>
          <Form.Control
            size="sm"
            onChange={(e) => setPosition(e.target.value)}
            value={position}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} sm="6">
          <Form.Label size="sm">Address</Form.Label>
          <Form.Control
            size="sm"
            onChange={(e) => setStreetAddress(e.target.value)}
            value={streetAddress}
          />
        </Form.Group>

        <Form.Group as={Col} sm="2">
          <Form.Label size="sm">Postal Code</Form.Label>
          <Form.Control
            size="sm"
            onChange={(e) => setPostalCode(e.target.value)}
            value={postalCode}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} sm="2">
          <Form.Label>City</Form.Label>
          <Form.Control
            size="sm"
            onChange={(e) => setCity(e.target.value)}
            value={city}
          />
        </Form.Group>

        <Form.Group as={Col} sm="3">
          <Form.Label size="sm">State</Form.Label>
          <Form.Control
            size="sm"
            onChange={(e) => setState(e.target.value)}
            value={state}
          />
        </Form.Group>

        <Form.Group as={Col} sm="3">
          <Form.Label size="sm">Country</Form.Label>
          <Form.Control
            size="sm"
            onChange={(e) => setCountry(e.target.value)}
            value={country}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} sm="1">
          <Form.Label>Lat</Form.Label>
          <Form.Control
            size="sm"
            onChange={(e) => setLat(e.target.value)}
            value={lat}
          />
        </Form.Group>

        <Form.Group as={Col} sm="1">
          <Form.Label size="sm">Lng</Form.Label>
          <Form.Control
            size="sm"
            onChange={(e) => setLng(e.target.value)}
            value={lng}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} sm="6">
          <Form.Label>Website</Form.Label>
          <Form.Control
            size="sm"
            onChange={(e) => setWebsite(e.target.value)}
            value={website}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} sm="6">
          <Form.Label>Livestream</Form.Label>
          <Form.Control
            size="sm"
            onChange={(e) => setLiveStream(e.target.value)}
            value={liveStream}
          />
        </Form.Group>
      </Form.Row>

      <Button
        variant="success"
        onClick={markVerified}
        style={{ marginRight: "20px" }}
      >
        <FontAwesomeIcon
          icon={faCheck}
          style={{ color: "green", marginRight: "6px" }}
        />{" "}
        Verify &amp; Save
      </Button>

      <Button variant="link" className="text-muted"  onClick={rejectCurrentSubmission}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          style={{ color: "red", marginRight: "6px" }}
        />
        Reject &amp; Delete
      </Button>
    </Form>
  );
}
