import StandardAPI from "./standard-api";

const submissionAPI = new StandardAPI("/v1/masjids");

export const submitMasjid = (token, masjidData) => {
  if (!token) document.location = "/";

  submissionAPI.headers["Authorization"] = `Bearer ${token}`;
  return submissionAPI.post(masjidData, { responseJson: true });
};

export const unverfiedList = (token) => {
  if (!token) document.location = "/";

  submissionAPI.headers["Authorization"] = `Bearer ${token}`;
  return submissionAPI.get("unverified", null);
};

export const rejectSubmission = (token, id) => {
  if (!token) document.location = "/";

  submissionAPI.headers["Authorization"] = `Bearer ${token}`;
  return submissionAPI.delete(id);
};

export const updateSubmission = (token, id, data) => {
  if (!token) document.location = "/";

  submissionAPI.headers["Authorization"] = `Bearer ${token}`;
  return submissionAPI.put(id + "/verify", data);
};

export default {
  submitMasjid,
  unverfiedList,
};
