import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import hero from "../images/hero.png";

export default function Hero(props) {
  return (
    <Row className="hero">
      <Col md="3">
        <img
          src={hero}
          alt="View Islamic Live Streams"
          style={{ height: "275px" }}
        />
      </Col>
      <Col md={{ offset: 3, span: 6 }} className="h-100 d-inline-block">
        <h2>
          Find Islamic Livestreams
          <br />
          from Across the Web
        </h2>
      </Col>
    </Row>
  );
}
