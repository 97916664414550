import StandardAPI from "./standard-api";

const authAPI = new StandardAPI("/v1/auth");

export const init = (token) => {
  authAPI.headers["Authorization"] = `Bearer ${token}`;
  try {
    return authAPI.post({ token });
  } catch (error) {
    console.log(error);
  }
};

export default {
  init,
};
