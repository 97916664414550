import React from "react";
import Modal from "react-bootstrap/Modal";
import { GoogleLogin } from "react-google-login";

export default function AuthModal(props) {
  const clientId =
    "908935358050-f84flno3n9nclic1hk54vefqh3jdivs8.apps.googleusercontent.com";

  const authSuccess = (googleUser) => {
    // const googleIdToken = googleUser.getAuthResponse().id_token;
    document.location = "/submission";
    // profile = googleUser.getBasicProfile();

    //console.log({googleIdToken, profile});
  };

  const authFail = (response) => {
    console.log(response);
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className="text-center"
        style={{ borderBottom: "none" }}
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ textAlign: "center", width: "100%" }}
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>
          You must sign in <br />
          to perform this action.
        </p>
        <GoogleLogin
          onClick={props.onHide}
          clientId={clientId}
          buttonText="Login with Google"
          onSuccess={authSuccess}
          onFailure={authFail}
          isSignedIn={true}
          cookiePolicy={"single_host_origin"}
        />
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "none" }}>
        <br />
      </Modal.Footer>
    </Modal>
  );
}
