import React from "react";
import { useLocation } from "react-router-dom";
import Ayat from "./Ayat";

export default function WhyCyber(props) {
  let ayat = "";

  const location = useLocation();
  if (location.pathname != "/") {
    ayat = <Ayat />;
  }

  return <div className="App" style={{ textAlign: "left", fontSize: "18px" }}><h3>Why Cyber</h3>
	{ayat}
<br></br>
<p>
Covid-19 situation has presented lots of challenges for our local masajid. Few established masajid had an online presence already but a lot of the other ones need a lot of help so they can reach out to their communities and continue to satisfy their religious needs in terms of Khutbas, lectures and more. One may find people sending those links using messaging apps and then they would be lost and the links have to be sent again, next Friday. Also Ramadan is right around the corner so that touches everyones heart and missing meeting everyone at masjid Iftars and the taraweeh. 
</p>
<p>
So we at cybermasjid.com decided to help everyone by making this simple website to ease it for our fellow brothers and sisters for benefiting from continue to attend their local masajid lectures, khutbas, taraweeh and more as well as of other shayukh, their lectures, workshops, activities and even their online prayers for those who believe in following them. 
</p>
<p>
All we seek from you is to make dua for our collective success in this dunya and the Hereafter. 
</p>
<p>
Jazakallahukhairun.
</p>
	</div>;
}
