import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SearchResults from "./SearchResults";

export default function SearchBox(props) {
  const refContainer = useRef({});
  const [searchCity, setSearchCity] = useState("");
  const [searchResults, setSearchResults] = useState("");

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("autocomplete-search-city"),
      { types: ["(cities)"] }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      const coordinates = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      console.log({ coordinates });

      setSearchCity(document.getElementById("autocomplete-search-city").value);
      refContainer.current.coordinates = coordinates;
      refContainer.current.startedSearching = false;
    });
  });

  const handleSubmit = (e) => {
    const coordinates = refContainer.current.coordinates;

    if (coordinates && coordinates.lat && coordinates.lng) {
      setSearchResults(
        <SearchResults
          coordinates={coordinates}
          searchCity={searchCity}
        ></SearchResults>
      );
    }
    else {
      console.log('Invalid location');
      setSearchResults(<div style={{marginBottom: 50}}>Could not find a location by that name</div>);
    }
  };

  const handleClick = (e) => {
    if (!refContainer.current.startedSearching) {
      window.history.pushState(null, "Search", "/search");
    } else {
      props.setSearch(true);
    }
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Form style={{ width: "100%", margin: "20px" }}>
        <Form.Group as={Row}>
          <Col lg={{ span: 4, offset: 3 }}>
            <Form.Control
              type="input"
              size="lg"
              id="autocomplete-search-city"
              placeholder="San Francisco, CA"
            />
            {/* <small>Search by Name</small> */}
          </Col>
          <Col lg={2}>
            <Button size="lg" onClick={handleSubmit} block>
              Search
            </Button>
          </Col>
        </Form.Group>
      </Form>

      {searchResults}
    </div>
  );
}
