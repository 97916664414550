import React from "react";

export default function Fatwas(props) {
  return <div className="App" style={{ textAlign: "left", fontSize: "18px" }}><h3>Fatwas on this topic</h3>
<br></br>
<p>
<b>Question: Can we pray behind a virtual Imam in the current Covid-19 situation?</b>
<br></br>
Answer: This is a common question people are asking. Different people have forwarded different opinions regarding this. We respect all school of thoughts regarding this and we are here to serve all opinions to benefit and enjoy the services provided by their trusted leaders. 
<br></br>
Below are some of the opinions we received on this topic: 
<ul>
<li>Arabic</li>
<ul>
<li><a href='files/Fatwa1.pdf'>Sh. Abdullah Yusuf Al Judai - European Council for Fatwa and Research</a></li>
<li><a href='files/Fatwa2.pdf'>Sh. Abdullah bin Tahir At-Tanani As-Susi - Maghrib</a></li>
<li><a href='files/Fatwa3.pdf'>Sh. Mohammad Al-Hasan AdDado</a></li>
<li><a href="https://www.youtube.com/watch?v=lPZQ6-5J4iI">Sh. AlHussain Bitt Saeed from Morocco (Youtube)</a> </li>
</ul>
<li>English</li>
<ul>
<li><a href="https://www.youtube.com/watch?v=pQlJIHASfRY">Sh. Yasir Qadhi (Youtube lecture)</a> </li>
<li><a href='files/Fatwa4.pdf'>Dr. Mansur Ali</a></li>
<li><a href="https://drive.google.com/file/d/1LlIvQ-7lK4kGOqBBEOmJWLDPU_NqE1za/view?fbclid=IwAR2r380ZRDVcmjUAZupvRrV7N3JBcMw4Bx2wpwhxuzFqhocDngjAbLpMN0Y">Medina Institute - summary paper on this (Google Drive)</a></li>
</ul>
</ul>
Please do respect other people's opinions in regards to the Deen and do what seems truth/right/correct to you and brings you closer to Allah.
</p>
<br></br>
	</div>;
}
