import React from "react";

export default function PrivacyPolicy(props) {
  return <div className="App" style={{ textAlign: "left", fontSize: "14px" }}>
<h4>Privacy Policy</h4>
<p>
This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
</p>
<br></br>
<p>
<b>What personal information do we collect from the people that visit our website?</b>
<br></br>
When using our website, as appropriate, you may be asked to authenticate using your gmail email to help you with your experience in using our website. Only your public information with your gmail profile, your masjid submissions and favorites are stored.
</p>
<br></br>
<p>
<b>When do we collect information?</b>
<br></br>
We collect information from you when you signin on our website using gmail to use the services offered at our website or enter information on our site.
</p>
<br></br>
<p>
<b>How do we use your information?</b>
<br></br>
We may use such information in the following ways:
<ul>
<li>To personalize user's experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
<li>To improve our website in order to better serve you.</li>
<li>To allow us to better service you in responding to your customer service requests.</li>
<li>To administer a contest, promotion, survey or other site feature.</li>
<li>To quickly process your requests.</li>
<li>To send periodic emails regarding your requests, submissions or other services.</li>
</ul>
</p>
<br></br>
<p>
<b>How do we protect visitor information?</b>
<br></br>
Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.
Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive information you supply is encrypted via Secure Socket Layer (SSL) technology.
We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.
</p>
<br></br>
<p>
<b>Do we use 'cookies'?</b>
<br></br>
Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember you and process your preferences. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
We use cookies to:
<ul>
<li>Understand and save user's preferences for future visits.</li>
<li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third party services that track this information on our behalf.</li>
</ul>
You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.
If you disable cookies off, some features will be disabled. It will turn off some of the features that make your site experience more efficient and some of our services will not function properly.
</p>
<br></br>
<p>
<b>How can you opt out, remove or modify information you have provided to us?</b>
<br></br>
You can request to have your information removed by clicking on the Contact Us button on this or the home page.
Please note that we may maintain information about a masjid you submitted. 
</p>
<br></br>
<p>
<b>Third Party Disclosures</b>
<br></br>
We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.
However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
</p>
<br></br>
<p>
<b>Third party Links</b>
<br></br>
Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
</p>
<br></br>
<p>
<b>Transfer Of Your Personal Information</b>
<br></br>
Your information, including personal information, may be transferred to and maintained on computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your personal information will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
</p>
<br></br>
<p>
<b>Disclosure Of Your Personal Information</b>
<br></br>
If we are involved in a merger, acquisition or asset sale, your personal information may be transferred. We will provide notice before your personal information is transferred and becomes subject to a different Privacy Policy.
Under certain circumstances, we may be required to disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
</p>
<br></br>
<p>
<b>Retention of Your Personal Information</b>
<br></br>
We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.

</p>
<br></br>
<p>
<b>Information Regarding Your Data Protection Rights Under General Data Protection Regulation (GDPR)</b>
<br></br>
For the purpose of this Privacy Policy, we are a Data Controller of your personal information.
If you are from the European Economic Area (EEA), our legal basis for collecting and using your personal information, as described in this Privacy Policy, depends on the information we collect and the specific context in which we collect it. We may process your personal information because:
<ul>
<li>You have given us permission to do so</li>
<li>The processing is in our legitimate interests and it's not overridden by your rights</li>
<li>To comply with the law</li>
</ul>
If you are a resident of the European Economic Area (EEA), you have certain data protection rights. In certain circumstances, you have the following data protection rights:
<ul>
<li>The right to access, update or to delete the personal information we have on you</li>
<li>The right of rectification</li>
<li>The right to object</li>
<li>The right of restriction</li>
<li>The right to data portability</li>
<li>The right to withdraw consent</li>
</ul>
Please note that we may ask you to verify your identity before responding to such requests.
You have the right to complain to a Data Protection Authority about our collection and use of your personal information. For more information, please contact your local data protection authority in the European Economic Area (EEA).
</p>
<br></br>
<p>
<b>Service Providers</b>
<br></br>
We employ third party companies and individuals to facilitate our Website ("Service Providers"), to provide our Website on our behalf, to perform Website-related services. 
</p>
<br></br>
<p>
<b>Contacting Us</b>
<br></br>
If there are any questions regarding this privacy policy you may contact us using the Contact Us link.
</p>
</div>;

}
