import React from "react";

export default function FAQ(props) {
  return <div className="App" style={{ textAlign: "left", fontSize: "18px" }}><h3>Frequently Asked Questions</h3>
<br></br>
<p>
<b>Question: Can we pray behind a virtual Imam in the current Covid-19 situation?</b>
<br></br>
Answer: This is a common question people are asking so we have made a separate page for this. Please visit the <a href="/fatwas">Fatwas</a> link at the top.
</p>
<br></br>
<p>
<b>Question: What is the process for making a masjid submission?</b>
<br></br>
Answer: Click on Masjid Submission at the top of the page and enter all the information and click submit. Once you submit information, our site admins will verify the data for correctness and then approve it to appear on our website. We encourage you to provide all the masjid data in the form. 
</p>
	</div>;
}
