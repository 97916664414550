import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { unverfiedList } from "./SubmissionAPI";
import SubmissionForm from "./SubmissionForm";

export default function ListSubmissions(props) {
  const [verifyList, setVerifyList] = useState([]);

  const [cookies] = useCookies(["session"]);
  const session = cookies.session || null;

  useEffect(() => {
    const getList = async () => {
      const list = await unverfiedList(session);
      if (list.masjids) {
        setVerifyList(list.masjids);
      }
    };

    if (!verifyList.length) {
      getList();
    }
  }, [verifyList, session]);

  let masjidList = verifyList.map((masjid) => {
    return <SubmissionForm masjid={masjid} />;
  });

  return (
    <div className="App">
      Submissions List
      {masjidList}
    </div>
  );
}
