import React from "react";

export default function Ayat() {
  return (
    <div style={{ marginTop: "50px" }}>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
	إِنَّمَا يَعْمُرُ مَسَاجِدَ اللَّهِ مَنْ آمَنَ بِاللَّهِ وَالْيَوْمِ الْآخِرِ وَأَقَامَ الصَّلَاةَ وَآتَى الزَّكَاةَ وَلَمْ يَخْشَ إِلَّا اللَّهَ ۖ فَعَسَىٰ أُولَٰئِكَ أَن يَكُونُوا مِنَ الْمُهْتَدِينَ - 9:18
      </div>
      <div style={{ fontSize: "16px" }}>
	<i>Indeed, the true inhabitants of the mosques of Allah is the one who believes 
	in Allah and the Last Day and the one who establishes Salah and pays Zakah and 
	who fears none but Allah. So, it is hoped that they are to be among those on the right path.</i>
      </div>
    </div>
  );
}
