const requestOptions = {
  response: false,
};

export default class StandardAPI {
  constructor(endpoint) {
    this.host = process.env.REACT_APP_API_HOST || "https://api.cybermasjid.com";
    this.endpoint = endpoint || "/";
    this.headers = {
      "Content-Type": "application/json",
    };
  }

  async post(data, options = requestOptions) {
    const response = await fetch(this.host + this.endpoint, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    });

    return [await response.json(), response];
  }

  async get(id, param, options) {
    const resourceId = id ? `/${id}` : "";
    const url = this.host + this.endpoint + resourceId;

    let query = "";

    if (param) {
      let separator = "";
      query = "?";

      Object.keys(param).forEach((key) => {
        query += separator + key + "=" + param[key];
        separator = "&";
      });
    }

    const response = await fetch(url + query, {
      method: "GET",
      headers: this.headers,
    });

    return response.json();
  }

  async put(id, data) {
    const resourceId = id ? `/${id}` : "";
    const url = this.host + this.endpoint + resourceId;

    const response = await fetch(url, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(data),
    });

    return [await response.json(), response];
  }

  patch(id, data) {}

  async delete(id) {
    const resourceId = id ? `/${id}` : "";
    const url = this.host + this.endpoint + resourceId;

    const response = await fetch(url, {
      method: "DELETE",
      headers: this.headers,
    });

    return [await response.json(), response];
  }
}
