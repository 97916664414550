import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useCookies } from "react-cookie";
import { Redirect } from "react-router-dom";
import submissionAPI from "./SubmissionAPI";

export default function Submission(props) {
  const [labelColSm, inputColSm] = [4, 6];
  const refContainer = useRef({});

  const [cookies, setCookie] = useCookies(["session", "toaster", "x-sp-set"]);
  const session = cookies.session || null;
  const sp = cookies["x-sp-set"] || null;

  const submissionsList = sp ? (
    <div style={{ textAlign: "right" }}>
      <small>
        <Link className="text-muted" to="/list-submissions">
          List Submissions
        </Link>
      </small>
    </div>
  ) : (
    ""
  );

  const [masjid, setMasjid] = useState("");
  const [position, setPosition] = useState("public");
  const [address, setAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [liveStream, setLiveStream] = useState("");

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("autocomplete-city"),
      { types: ["(cities)"] }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      const coordinates = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      const addressComponents = place.address_components;

      let location = {};
      addressComponents.forEach((ac) => {
        if (ac.types[1] === "political") {
          const type = ac.types[0];

          switch (type) {
            case "locality": {
              location.city = ac.long_name;
              break;
            }
            case "administrative_area_level_1": {
              location.state = ac.long_name;
              break;
            }
            case "country": {
              location.country = ac.long_name;
              break;
            }
            default: {
              break;
            }
          }
        }
      });

      refContainer.current.googlePlace = place;
      refContainer.current.coordinates = coordinates;
      refContainer.current.location = location;
      refContainer.current.addressComponents = addressComponents;
    });
  });

  const handleSubmit = async (e) => {
    const payload = {
      masjid,
      position,
      address,
      googlePlace: refContainer.current.googlePlace,
      coordinates: refContainer.current.coordinates,
      location: refContainer.current.location,
      addressComponents: refContainer.current.addressComponents,
      website,
      liveStream,
    };

    console.log({ session, payload });

    try {
      await submissionAPI.submitMasjid(session.googleIdToken, payload);
      setCookie("toaster", {
        message:
          "The masjid was submitted successfully and once verified, will be made available.",
      });
      document.location = "/";
    } catch (error) {
      console.error(error);
    }
  };

  if (!session) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <div className="App">
      {submissionsList}
      <h3>Submit a Masjid</h3>
      <div style={{ textAlign: "left", fontSize: "16px" }}>
	<p>
	Thank you for wanting to share your masjid information with us. We value it deeply. 
	<br></br>
	Please follow these simple steps to make your submission:
	<ol>
	<li>Check if your masjid has a Live youtube channel</li>
	<ol>
	    <li>Yes, go to the next bullet</li>
	    <li>No, please first setup your masjid youtube channel as follows</li>
	    <ol>
	         <li><a href="https://support.google.com/youtube/answer/2474026?hl=en">Google Tutorial</a></li>
	         <li><a href="https://www.youtube.com/watch?v=Xka4OLgzW9Q">Online Video (suggested by someone)</a></li>
	    </ol>
	</ol>
	<li>Fill in the form below</li>
	<li>After the information is reviewed and confirmed, it will be enabled</li>
	</ol>
	</p>
      </div>
      <br />
      <Form style={{ fontSize: "16px", width: "80%", textAlign: "left" }}>
        <Form.Group as={Row}>
          <Form.Label column sm={labelColSm}>
            Masjid Name
          </Form.Label>
          <Col sm={inputColSm}>
            <Form.Control
              type="input"
              onChange={(e) => setMasjid(e.target.value)}
            />
          </Col>
        </Form.Group>

        {/* <Form.Group as={Row}>
                <Col sm={{span: inputColSm, offset: labelColSm}}>
                    <Form.Check type="checkbox" label=" &nbsp; I am associated with this mosque" />
                </Col>
            </Form.Group> */}

        <Form.Group as={Row}>
          <Form.Label column sm={labelColSm}>
            Your Role/Position at the Masjid
          </Form.Label>
          <Col sm={inputColSm - 3}>
            <Form.Control
              type="input"
              onChange={(e) => setPosition(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={labelColSm}>
            Street Address
          </Form.Label>
          <Col sm={inputColSm}>
            <Form.Control
              type="input"
              onChange={(e) => setAddress(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={labelColSm}>
            City
          </Form.Label>
          <Col sm={inputColSm - 2}>
            <Form.Control id="autocomplete-city" type="input" />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={labelColSm}>
            Masjid Website
          </Form.Label>
          <Col sm={inputColSm}>
            <Form.Control
              type="input"
              onChange={(e) => setWebsite(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={labelColSm}>
            Youtube liveStream
          </Form.Label>
          <Col sm={inputColSm}>
            <Form.Control
              type="input"
              onChange={(e) => setLiveStream(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Row>
          <Col sm={{ span: inputColSm, offset: labelColSm }}>
            <Button onClick={handleSubmit}>Submit Masjid</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
