import React from "react";
import { useLocation } from "react-router-dom";
import Ayat from "./Ayat";
import Hero from "./Hero";
import SearchBox from "./SearchBox";

export default function Home() {
  let hero = "";
  let ayat = "";

  const location = useLocation();
  if (location.pathname === "/") {
    hero = <Hero />;
    ayat = <Ayat />;
  }

  return (
    <div className="App">
      {hero}
      <SearchBox sm="12"></SearchBox>
      <div style={{ textAlign: "center", fontSize: "16px" }}>
      Welcome to cybermasjid.com. Given the corona virus (Covid-19) situation, we decided to make this website to help all of us easily find our masjid online presence. You can find your favorite masjids online and listen to their live lectures, khutbas and prayers etc. For more details <a href="why-cyber">click here</a>
<p>
You can easily search for your masjid by your area, add them to your favorites. If your masjid is missing, please click here (takes Submission page) to submit the information to our database.
</p>
	</div>
    </div>
  );
}
